<template>
  <!-- loading START -->
  <transition name="fade" mode="out-in">
    <div class="loading loading-default" v-if="loadingVisible">
      <div class="loading-default-container">

        <!-- spinner START -->
        <div class="loader-container">
          <div class="loader loader-one"></div>
          <div class="loader loader-two"></div>
        </div>
        <!-- spinner END -->

        <h4 v-html="headline"></h4>
        <div class="excerpt" v-html="excerpt"></div>
      </div>
    </div>
  </transition>
  <!-- loading END -->
</template>

<script>
export default {
  name: 'loading-default',
  props: {
    loadingVisible: {
      type: Boolean,
      default: false,
    },

    headline: {
      type: String,
    },

    excerpt: {
      type: String,
    },
  },
};
</script>
